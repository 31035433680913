import { Box, Stack, CircularProgress, Typography } from '@mui/material';

function LoadingState(props: { loadingText?: string }) {
    const { loadingText = 'Loading...' } = props;

    return (
        <Stack
            sx={{
                position: 'fixed',
                top: 0,
                bottom: 0,
                right: 0,
                left: 0,
                alignItems: 'center',
                justifyContent: 'center',
                p: 2.5,
                zIndex: 9999,
            }}
        >
            <CircularProgress color="primary" />
            <Box sx={{ mt: 2.5 }}>
                <Typography variant="h4" sx={{ color: 'grey.900' }}>
                    {loadingText}
                </Typography>
            </Box>
        </Stack>
    );
}

export default LoadingState;
