import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import Groups from 'src/constants/Groups';
import { selectFacilityId } from 'src/store/configSlice';
import { useGetGroupsQuery } from 'src/store/sageAdminApi';

interface UseFacilityGroupsProps {
    facilityId?: string;
}

function useFacilityGroups(props: UseFacilityGroupsProps = {}) {
    const { facilityId } = props;

    const storeFacilityId = useSelector(selectFacilityId);

    const searchFacilityId = facilityId || storeFacilityId;

    const { data: facilityGroups, ...rest } = useGetGroupsQuery(
        { facilityId: searchFacilityId },
        { skip: !searchFacilityId }
    );

    const { groupIdMap, groupNameMap } = useMemo(
        () => ({
            groupIdMap: new Map(facilityGroups?.map((group) => [group.id, group]) || []),
            groupNameMap: new Map(facilityGroups?.map((group) => [group.name, group]) || []),
        }),
        [facilityGroups]
    );

    const careGroup = groupNameMap.get(Groups.CareStaff);
    const nurseMedtechGroup = groupNameMap.get(Groups.NurseMedTech);
    const sageSupportGroup = groupNameMap.get(Groups.SageSupport);

    return {
        ...rest,
        facilityGroups,
        groupIdMap,
        groupNameMap,

        careGroup,
        nurseMedtechGroup,
        sageSupportGroup,
    };
}

export default useFacilityGroups;
