import get from 'lodash/get';

type Direction = 'asc' | 'desc';

export function splitByNumbers(s: string): (string | number)[] {
    const parts = s.split(/(\d+)/);
    return parts
        .map((part) => (isNaN(parseInt(part, 10)) ? part : parseInt(part, 10)))
        .filter(Boolean);
}

export function numberAwareComparatorAsc(a?: string, b?: string): number {
    return numberAwareComparator(a, b, 'asc');
}

export function numberAwareComparatorDesc(a?: string, b?: string): number {
    return numberAwareComparator(a, b, 'desc');
}

export function numberAwareComparator<T>(
    a: T,
    b: T,
    accessor?: string,
    direction: Direction = 'asc'
): number {
    const itemOne = accessor ? get(a, accessor) : a;
    const itemTwo = accessor ? get(b, accessor) : b;

    if (itemOne === itemTwo) {
        return 0;
    } else if (itemOne === '' && itemTwo !== '') {
        return direction === 'asc' ? 1 : -1;
    } else if (itemOne !== '' && itemTwo === '') {
        return direction === 'asc' ? -1 : 1;
    }

    const aParts = splitByNumbers(itemOne);
    const bParts = splitByNumbers(itemTwo);

    for (let i = 0; i < aParts.length; i++) {
        const aPart = aParts[i];
        const bPart = bParts[i];

        if (aPart === bPart) {
            continue;
        }

        // If one part is undefined, it should always come first
        if (aPart === undefined && bPart !== undefined) {
            return direction === 'asc' ? -1 : 1;
        } else if (aPart !== undefined && bPart === undefined) {
            return direction === 'asc' ? 1 : -1;
        } else if (typeof aPart === 'number' && typeof bPart === 'number') {
            return direction === 'asc' ? aPart - bPart : bPart - aPart;
        } else if (typeof aPart === 'number') {
            return direction === 'asc' ? -1 : 1;
        } else if (typeof bPart === 'number') {
            return direction === 'asc' ? 1 : -1;
        }

        return direction === 'asc' ? aPart.localeCompare(bPart) : bPart.localeCompare(aPart);
    }

    // If we get here, it means that one string is a substring of the other
    if (aParts.length < bParts.length) {
        return direction === 'asc' ? -1 : 1;
    }

    return 0;
}

export function numberAwareSort<T>(
    items: T[],
    accessor: string,
    direction: Direction = 'asc'
): T[] {
    return [...items].sort((a, b) => numberAwareComparator(a, b, accessor, direction));
}
