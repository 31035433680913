import { Properties } from 'csstype';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTween } from 'react-use';

import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { faCheck, faHouse, faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, Box, Button, CircularProgress, Collapse, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import DrawerBody from './DrawerBody';
import DrawerFooter from './DrawerFooter';
import { DrawerComponentProps } from './DrawerManager';
import DrawerTitle from './DrawerTitle';
import { AnalyticsContext } from 'src/AnalyticsContext';
import EmptyButtonIcon from 'src/assets/images/device-icons/empty-button-icon.png';
import { TaskStatuses } from 'src/constants/TaskStatuses';
import useImageCache from 'src/hooks/useImageCache';
import { useGetCareConfirmationTrackerQuery, useUpdateTaskMutation } from 'src/store/sageAdminApi';
import type { Resident, Task, Unit } from 'src/store/sageAdminApi';
import { snackActions } from 'src/utils/snackBarTools';

const PollingInterval = 1_000;

const ButtonIcon = {
    Width: 180,
    Padding: 40,
    TopOffset: 8,
};

const AvatarWidth = ButtonIcon.Width - ButtonIcon.Padding * 2;
const FontAwesomeIconStyles: Properties = {
    position: 'absolute',
    width: `${ButtonIcon.Padding * 2}px`,
    height: `${ButtonIcon.Padding * 2}px`,
    top: `calc(50% - ${ButtonIcon.Padding}px - ${ButtonIcon.TopOffset / 4}px)`,
};
const ProgressWidth = 1;
const ProgressSize = ButtonIcon.Width - ButtonIcon.TopOffset / 2 - ProgressWidth * 2;
const ConfirmationWindowMs = 30_000;

interface CareConfirmationDrawerProps extends DrawerComponentProps {
    task: Task;
    resident?: Resident;
    unit?: Unit;
}

function CareConfirmationDrawer(props: CareConfirmationDrawerProps) {
    const { onNext, onClose, task: taskToConfirm, resident, unit } = props;

    const { trackEvent } = useContext(AnalyticsContext);
    const { palette } = useTheme();

    const taskId = taskToConfirm?.id || '';
    const isResidentPendant = !!resident;
    const isUnitButton = !isResidentPendant && !!unit;

    const [shouldPoll, setShouldPoll] = useState(false);
    const [isFailed, setIsFailed] = useState(false);
    const [hasStartedConfirmation, setHasStartedConfirmation] = useState(false);

    const confirmationAttempts = useRef<number>(0);

    const successTimeout = useRef<NodeJS.Timeout | null>(null);

    const [imageData] = useImageCache(resident?.id);

    const {
        data: taskSummary,
        isFetching,
        isLoading,
        refetch: refetchTask,
    } = useGetCareConfirmationTrackerQuery(
        { taskId },
        {
            pollingInterval: shouldPoll ? PollingInterval : 0,
            refetchOnFocus: true,
            refetchOnMountOrArgChange: true,
            skip: !taskId,
        }
    );
    const { task, timeSinceLastUpdate = 0 } = taskSummary || {};
    const isConfirmed = task?.status === TaskStatuses.OpenConfirmed.id;
    const isConfirming = task?.status === TaskStatuses.OpenConfirming.id;
    const msSinceLastUpdate = parseInt(timeSinceLastUpdate || '0');
    const msRemaining = ConfirmationWindowMs - msSinceLastUpdate;

    const countdownTimerTween = useTween('linear', isConfirming ? msRemaining : 0);

    /**
     * This value is used to calculate the progress of the circular progress bar, it needs
     * to be an integer between 0 and 100, inclusive.
     *
     * Since we count down, we start at 100, and subtract the percentage of time remaining.
     * Since the tween is a linear value from 0 to 1, it is effectively a percentage of the msRemaining value
     * that has passed.
     *
     * For example, if 15 seconds have passed, and the tween is at 0.5, then 50% of the
     * time remaining has passed (i.e., 7.5 seconds). To get the actual progress value, we:
     * 1. add the time that has passed to the time remaining
     * 2. divide by the total time (30 seconds)
     * 3. multiply by 100 to get a percentage
     * 4. subtract from 100 to get the progress value (i.e., the percentage of time remaining)
     */
    const progressValue = Math.floor(
        100 - ((msSinceLastUpdate + msRemaining * countdownTimerTween) / ConfirmationWindowMs) * 100
    );

    const drawerTitle = useMemo(() => {
        if (isFailed) {
            return 'No press detected';
        } else if (isConfirmed) {
            return 'Confirmed!';
        } else {
            return isResidentPendant
                ? 'Press the resident pendant to continue'
                : `Press any wall button in ${unit?.displayName} to continue`;
        }
    }, [isConfirmed, isFailed, isResidentPendant, unit]);

    const [updateTask, { isLoading: isUpdatingTask }] = useUpdateTaskMutation();

    const shouldSkipChecks = isLoading || isFetching || isUpdatingTask || isFailed || !task;

    const handleStartConfirmation = useCallback(async () => {
        const { status: taskStatus } = task || {};

        if (taskStatus === TaskStatuses.ClosedResolved.id) {
            snackActions.error('This alert has been resolved');
            onClose();
            return;
        } else if (taskStatus === TaskStatuses.OpenConfirmed.id) {
            return;
        }

        try {
            setIsFailed(false);
            setHasStartedConfirmation(false);

            if (taskStatus !== TaskStatuses.OpenConfirming.id) {
                await updateTask({
                    taskId,
                    createOrUpdateTaskRequest: {
                        status: TaskStatuses.OpenConfirming.id,
                    },
                }).unwrap();
            } else if (taskStatus === TaskStatuses.OpenConfirming.id) {
                setHasStartedConfirmation(true);
            }
            setShouldPoll(true);
            confirmationAttempts.current += 1;
        } catch (error: any) {
            const { code } = error?.data || {};
            if (code === 409) {
                refetchTask();
            } else {
                datadogRum.addError(error, {
                    message: `Error starting care confirmation for alert ${taskId}`,
                    taskId,
                });
            }
        }
    }, [onClose, refetchTask, task, taskId, updateTask]);

    const handleRetryConfirmation = useCallback(async () => {
        trackEvent('Care Confirmation Retried', { taskId, isResidentPendant, isUnitButton });
        handleStartConfirmation();
    }, [handleStartConfirmation, isResidentPendant, isUnitButton, taskId, trackEvent]);

    const handleConfirmationSuccess = useCallback(
        (msSinceLastUpdate: number) => {
            setShouldPoll(false);
            setIsFailed(false);
            setHasStartedConfirmation(false);

            if (!successTimeout.current) {
                successTimeout.current = setTimeout(() => {
                    trackEvent('Care Confirmed', {
                        taskId,
                        secondsElapsed: msSinceLastUpdate / 1_000,
                        confirmationAttempts: confirmationAttempts.current,
                        isResidentPendant,
                        isUnitButton,
                    });
                    onNext({ task });
                }, 2_000);
            }

            return () => {
                successTimeout.current && clearTimeout(successTimeout.current);
                confirmationAttempts.current = 0;
            };
        },
        [isResidentPendant, isUnitButton, onNext, task, taskId, trackEvent]
    );

    const handleCancel = async () => {
        trackEvent('Care Confirmation Cancelled', { taskId, isResidentPendant, isUnitButton });

        try {
            if (task?.status === TaskStatuses.OpenConfirming.id) {
                await updateTask({
                    taskId,
                    createOrUpdateTaskRequest: {
                        status: TaskStatuses.OpenClaimed.id,
                    },
                }).unwrap();
            }
        } catch (error: any) {
            const { code } = error?.data || {};
            if (code !== 409) {
                datadogRum.addError(error, {
                    message: `Error cancelling care confirmation for alert ${taskId}`,
                    taskId,
                });
            }
        } finally {
            setShouldPoll(false);
            setIsFailed(false);
            onClose();
        }
    };

    const handleClickButtonIcon = () => {
        trackEvent('Device Icon Clicked', {
            isResidentPendant,
            isUnitButton,
            taskStatus: task?.status,
            secondsSinceLastUpdate: (msSinceLastUpdate / 1_000).toFixed(2),
        });
    };

    useEffect(() => {
        /**
         * Only handle for non-care confirmation related statuses,
         * allow the next useEffect to handle for care confirmation - specific statuses
         */
        switch (task?.status) {
            case TaskStatuses.OpenUnclaimed.id:
                datadogLogs.logger.info(
                    'Closing care confirmation drawer since alert was unclaimed',
                    { taskId: task.id }
                );
                snackActions.error('This alert was unclaimed, please claim and try again');
                onClose();
                break;
            case TaskStatuses.ClosedResolved.id:
                datadogLogs.logger.info(
                    'Closing care confirmation drawer since alert has been resolved',
                    { taskId: task.id }
                );
                snackActions.error('This alert has been resolved');
                onClose();
                break;
            default:
                break;
        }
    }, [onClose, task]);

    useEffect(() => {
        if (shouldSkipChecks) {
            return;
        }

        const { status: taskStatus } = task || {};
        if (!hasStartedConfirmation && taskStatus === TaskStatuses.OpenConfirming.id) {
            // Note: set this flag here instead of in handleStartConfirmation to avoid
            // the split second when `isFetching === false` and `taskStatus === 'OPEN_CONFIRMED'`,
            // which would cause this to fail immediately
            setHasStartedConfirmation(true);
        }

        const handleConfirmationFailed = async () => {
            try {
                if (taskStatus !== TaskStatuses.OpenClaimed.id) {
                    await updateTask({
                        taskId,
                        createOrUpdateTaskRequest: {
                            status: TaskStatuses.OpenClaimed.id,
                        },
                    }).unwrap();
                }
            } catch (error: any) {
                const { code } = error?.data || {};
                if (code === 409) {
                    refetchTask();
                } else {
                    datadogRum.addError(error, {
                        message: 'Error failing care confirmation for alert',
                    });
                }
                setShouldPoll(code === 409);
            } finally {
                setIsFailed(true);
            }
        };

        if (taskStatus === TaskStatuses.OpenConfirmed.id) {
            handleConfirmationSuccess(msSinceLastUpdate);
        } else if (
            (hasStartedConfirmation &&
                taskStatus === TaskStatuses.OpenClaimed.id &&
                confirmationAttempts.current > 0) ||
            (taskStatus === TaskStatuses.OpenConfirming.id &&
                msSinceLastUpdate > ConfirmationWindowMs)
        ) {
            handleConfirmationFailed();
        }
    }, [
        handleConfirmationSuccess,
        hasStartedConfirmation,
        msSinceLastUpdate,
        onClose,
        refetchTask,
        shouldSkipChecks,
        task,
        taskId,
        updateTask,
    ]);

    useEffect(() => {
        if (!isLoading && !isFetching && !isUpdatingTask && confirmationAttempts.current < 1) {
            handleStartConfirmation();
        }
    }, [handleStartConfirmation, isFetching, isLoading, isUpdatingTask]);

    return (
        <>
            <DrawerTitle title={drawerTitle}>
                {!isFailed && !isConfirmed && (
                    <Typography
                        variant="body2"
                        sx={{
                            textAlign: 'center',
                        }}
                    >
                        Make sure you hear a click and see a light
                    </Typography>
                )}
            </DrawerTitle>
            <DrawerBody sx={{ alignItems: 'center' }}>
                <Box
                    onClick={handleClickButtonIcon}
                    sx={{
                        position: 'relative',
                        width: ButtonIcon.Width,
                        display: 'flex',
                        alignItems: 'flex-start',
                        justifyContent: 'center',
                    }}
                >
                    <img width={ButtonIcon.Width} src={EmptyButtonIcon} alt="" />

                    <CircularProgress
                        variant="determinate"
                        color="primary"
                        value={
                            isFailed ||
                            isConfirmed ||
                            !hasStartedConfirmation ||
                            isNaN(progressValue)
                                ? 100
                                : progressValue
                        }
                        size={ProgressSize}
                        thickness={isFailed || isConfirmed ? 0 : 1}
                        sx={{
                            position: 'absolute',
                            top: ButtonIcon.TopOffset - ProgressWidth,
                            scale: '-1 1',
                        }}
                    />

                    {isConfirmed ? (
                        <FontAwesomeIcon
                            icon={faCheck}
                            color={palette.success.main}
                            style={FontAwesomeIconStyles}
                        />
                    ) : isFailed ? (
                        <FontAwesomeIcon
                            icon={faXmark}
                            color={palette.error.main}
                            style={FontAwesomeIconStyles}
                        />
                    ) : (
                        <>
                            {isResidentPendant && (
                                <Avatar
                                    src={imageData || ''}
                                    alt={`${resident?.firstName} ${resident?.lastName}`}
                                    sx={{
                                        position: 'absolute',
                                        width: AvatarWidth,
                                        height: AvatarWidth,
                                        top: ButtonIcon.TopOffset / 4 + ButtonIcon.Padding,
                                    }}
                                >
                                    {`${resident?.firstName?.[0] ?? ''}${
                                        resident?.lastName?.[0] ?? ''
                                    }`.toLocaleUpperCase()}
                                </Avatar>
                            )}

                            {isUnitButton && (
                                <FontAwesomeIcon
                                    icon={faHouse}
                                    color={palette.primary.main}
                                    style={FontAwesomeIconStyles}
                                />
                            )}
                        </>
                    )}
                </Box>
            </DrawerBody>
            <DrawerFooter>
                <Collapse in={!isConfirmed}>
                    <Stack
                        sx={{
                            gap: 2.5,
                        }}
                    >
                        {isFailed && (
                            <Button
                                className="ctaButton"
                                color="primary"
                                variant="contained"
                                disableElevation
                                fullWidth
                                onClick={handleRetryConfirmation}
                                size="large"
                            >
                                Try Again
                            </Button>
                        )}

                        <Button
                            className="ctaButton"
                            color="primary"
                            disableElevation
                            disabled={isUpdatingTask}
                            fullWidth
                            onClick={handleCancel}
                            size="large"
                        >
                            Cancel
                        </Button>
                    </Stack>
                </Collapse>
            </DrawerFooter>
        </>
    );
}

export default CareConfirmationDrawer;
