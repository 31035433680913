import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { selectFacilityId } from 'src/store/configSlice';
import {
    CompanyPhone,
    StaffMember,
    useGetCompanyPhonesQuery,
    useGetOnCallStaffMembersQuery,
} from 'src/store/sageAdminApi';
import { numberAwareSort } from 'src/utils/listUtils';

export interface CompanyPhoneAllocation {
    staffMember?: StaffMember;
    companyPhone: CompanyPhone;
}

function useCompanyPhones() {
    const facilityId = useSelector(selectFacilityId);

    const {
        data: companyPhones,
        isError: errorFetchingCompanyPhones,
        isFetching: isFetchingCompanyPhones,
        isLoading: isLoadingCompanyPhones,
        refetch: refetchCompanyPhones,
    } = useGetCompanyPhonesQuery({ facilityId }, { skip: !facilityId });

    const {
        companyPhoneStaffMemberMapping,
        isError: errorFetchingOnCallStaffMembers,
        isFetching: isFetchingStaffMembers,
        isLoading: isLoadingStaffMembers,
        refetch: refetchStaffMembers,
    } = useGetOnCallStaffMembersQuery(
        { staffOnCallRequest: { facilityRid: facilityId } },
        {
            skip: !facilityId,
            selectFromResult: (results) => ({
                ...results,
                companyPhoneStaffMemberMapping: (results.data || []).reduce((acc, staffMember) => {
                    const facilityOnCall = staffMember.onCallStates?.find(
                        (facilityOnCallState) => facilityOnCallState?.facilityId === facilityId
                    );
                    if (!!facilityOnCall?.companyPhoneId) {
                        return { ...acc, [facilityOnCall.companyPhoneId]: staffMember };
                    }

                    return acc;
                }, {} as { [companyPhoneId: string]: StaffMember }),
            }),
        }
    );

    const { availableCompanyPhones, unavailableCompanyPhones } = useMemo(
        () =>
            numberAwareSort(companyPhones || [], 'displayName').reduce(
                (acc, companyPhone: CompanyPhone) => {
                    const { availableCompanyPhones, unavailableCompanyPhones } = acc;

                    const staffMemberAllocatedToPhone =
                        companyPhone && companyPhoneStaffMemberMapping[companyPhone.id!];
                    if (staffMemberAllocatedToPhone) {
                        unavailableCompanyPhones.push({
                            staffMember: staffMemberAllocatedToPhone,
                            companyPhone,
                        });
                    } else {
                        availableCompanyPhones.push({ companyPhone });
                    }
                    return { availableCompanyPhones, unavailableCompanyPhones };
                },
                { availableCompanyPhones: [], unavailableCompanyPhones: [] } as {
                    availableCompanyPhones: CompanyPhoneAllocation[];
                    unavailableCompanyPhones: CompanyPhoneAllocation[];
                }
            ),
        [companyPhones, companyPhoneStaffMemberMapping]
    );

    return {
        allCompanyPhones: companyPhones,
        availableCompanyPhones,
        unavailableCompanyPhones,

        errorFetchingCompanyPhones,
        errorFetchingOnCallStaffMembers,
        hasError: errorFetchingCompanyPhones || errorFetchingOnCallStaffMembers,

        isFetchingCompanyPhones,
        isFetchingStaffMembers,
        isFetching: isFetchingCompanyPhones || isFetchingStaffMembers,

        isLoadingCompanyPhones,
        isLoadingStaffMembers,
        isLoading: isLoadingCompanyPhones || isLoadingStaffMembers,

        refetchCompanyPhones,
        refetchStaffMembers,
        refetchAll: () => {
            refetchCompanyPhones();
            refetchStaffMembers();
        },
    };
}

export default useCompanyPhones;
